import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Message } from '../../types';
import { type MessageTemplate } from '../../types/messageTemplate';
import { type Action, ActionSheet } from '../ActionSheet';
import { ErrorMessage } from '../Game/GameCenter';
import { DeleteIcon } from '../icons/DeleteIcon';
import { RefreshIcon } from '../icons/RefreshIcon';
import { Loading } from '../Loading';
import { EditMessageTemplateModal } from '../MessageTemplate/Modals/Editor';

function MessageActionSheet(props: {
  onRevoke: () => void;
  onSend: () => void;
  messageStatus: string;
  disabled: boolean;
}): JSX.Element {
  const { onRevoke, onSend, messageStatus, disabled } = props;
  const actions: Action<string>[] = useMemo(() => {
    if (disabled) {
      return [];
    }
    if (
      messageStatus === 'Deleted' ||
      messageStatus === 'Pending' ||
      messageStatus === 'Error'
    ) {
      return [
        {
          kind: 'button',
          key: 'send',
          icon: <RefreshIcon />,
          text: 'Retry',
          onClick: onSend,
          className: 'disabled',
        },
      ];
    }
    if (messageStatus === 'Success') {
      return [
        {
          kind: 'button',
          key: 'revoke',
          icon: <DeleteIcon />,
          text: 'Delete',
          onClick: onRevoke,
          className: 'disabled text-red-002',
        },
      ];
    }
    return [];
  }, [onRevoke, onSend, messageStatus, disabled]);
  return <ActionSheet<string> actions={actions} />;
}

export const MessageRow = (props: {
  message: Message;
  onEditingTemplate: (messageTemplate: MessageTemplate | null) => void;
}): JSX.Element => {
  const { message, onEditingTemplate } = props;

  const [status, setStatus] = useState<string>(props.message.status);

  const {
    call: revokeCall,
    state: { transformed: revokeState },
  } = useAsyncCall(
    useCallback(async (messageId: string) => {
      return apiService.message.revokeMessage(messageId);
    }, [])
  );

  const {
    call: sendCall,
    state: { transformed: sendState },
  } = useAsyncCall(
    useCallback(async (messageId: string) => {
      return apiService.message.sendMessage(messageId);
    }, [])
  );

  const onRevoke = async (messageId: string) => {
    const resp = await revokeCall(messageId);
    if (resp) {
      setStatus(resp.data.status);
    }
    return;
  };

  const onSend = async (messageId: string) => {
    const resp = await sendCall(messageId);
    if (resp) {
      setStatus(resp.data.status);
    }
    return;
  };

  return (
    <tr key={message.id} className='h-10'>
      <td>
        <p className=' max-w-50 truncate'>{message.organization?.name}</p>
      </td>
      <td>{message.targetType}</td>
      <td>
        <p className='max-w-50 truncate'>
          {message.recipientData.mode === 'All'
            ? 'All'
            : message.recipientData?.recipients
                ?.map((r) => r.name)
                .join(', ') || ''}
        </p>
      </td>
      <td>
        <button
          type='button'
          className='underline truncate'
          onClick={() => {
            onEditingTemplate(message.messageTemplate);
          }}
        >
          {message.messageTemplate?.title}
        </button>
      </td>
      <td>{format(new Date(message.createdAt), 'MM/dd/yy h:mm aa')}</td>
      <td>{status}</td>
      <td>
        <MessageActionSheet
          onRevoke={() => onRevoke(message.id)}
          onSend={() => onSend(message.id)}
          messageStatus={status}
          disabled={revokeState.isRunning || sendState.isRunning}
        />
      </td>
    </tr>
  );
};

export const CampaignMessages = (props: {
  params: {
    orgId?: string;
    parentId?: string;
    campaignId?: string;
  };
  onMessagesLoad?: (messages: Message[]) => void;
}): JSX.Element => {
  const { params, onMessagesLoad } = props;

  const [editingTemplate, setEditingTemplate] =
    useState<MessageTemplate | null>(null);

  const onEditingTemplate = (messageTemplate: MessageTemplate | null) =>
    setEditingTemplate(messageTemplate);

  const { mutate, data, isValidating, error } = useSWR(
    ['/messages', params.campaignId, params.parentId, params.orgId],
    async () => {
      const resp = await apiService.message.queryMessages(params);
      return resp.data.messages;
    },
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (onMessagesLoad && data) {
      onMessagesLoad(data);
    }
  }, [data, onMessagesLoad]);

  return (
    <div>
      <table className='w-full mt-5'>
        <thead>
          <tr className='text-left h-14'>
            <th>Organization</th>
            <th>Target</th>
            <th>Recipients</th>
            <th>Message Template</th>
            <th>Sent</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((message) => (
              <MessageRow
                message={message}
                onEditingTemplate={onEditingTemplate}
              />
            ))}
        </tbody>
      </table>
      <div className='w-full'>
        {!error && data && data.length === 0 && (
          <div className='w-full mt-32 flex items-center justify-center font-bold text-secondary'>
            No Messages.
          </div>
        )}
        {isValidating && !data && <Loading />}
        {error && (
          <div className='w-full flex items-center justify-center'>
            <ErrorMessage text='Something went wrong' />
          </div>
        )}
      </div>

      {editingTemplate && (
        <EditMessageTemplateModal
          template={editingTemplate}
          onSubmit={() => {
            setEditingTemplate(null);
            mutate();
          }}
          onClose={() => setEditingTemplate(null)}
        />
      )}
    </div>
  );
};
